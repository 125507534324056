import React from "react"
import { graphql, Link } from "gatsby"
import { Styled } from "theme-ui"
import CurrencyFormat from 'react-currency-format'
import { Helmet } from "react-helmet"

import ChartTwo from "../components/ChartTwo"
import ChartFour from "../components/ChartFour"

import Header from "../components/Header"
import Footer from "../components/Footer"

import "../pages/index.css"

export default ({data}) => {

  console.log(data)

 //Create paragraph for breadcrumb links
  const breadcrumbs = <p><Link to={data.third.nodes[0].B1S}>
  {data.third.nodes[0].B1N}</Link></p>;

  //Sales for last 12 months with thousand separator
  const sales = <CurrencyFormat value={data.allRegionsRecentCsv.nodes[0].LSSales} 
  displayType={'text'} thousandSeparator={true} />;

//Location name with correct capitalisation  
  const locaC = data.third.nodes[0].Name;

  //Is Greater London?
  let otherAreas = "error"
  if (locaC == "Greater London") {
      otherAreas = <div><p>London boroughs:</p>     
      <ul class="bullet"> {data.allLookDistrictsCsv.nodes.map((node) => (
      <li><Link to={node.Slug}>{node.Name}</Link></li>
      ))}
      </ul></div>
  } else {
    otherAreas = <div><p>Counties in {locaC}:</p>     
    <ul class="bullet"> {data.second.nodes.map((node) => (
    <li><Link to={node.Slug}>{node.Name}</Link></li>
    ))}
    </ul></div>
  }

//Price for last 12 months.
  const lastYearPrice = data.allRegionsRecentCsv.nodes[0].LastYear;

//Price for previous 13-24 months  
  const prevPrice = data.allRegionsRecentCsv.nodes[0].PreviousYear;

//Price for last 12 months formatted as currency  
  const lastYearPriceF = <CurrencyFormat value={data.allRegionsRecentCsv.nodes[0].LastYear} 
  displayType={'text'} thousandSeparator={true} prefix={'£'} />

// Does average price increase or decrease when comparing last 12 months with previous?  
  let lastYearInc = "error"
  if (lastYearPrice > prevPrice) {
    lastYearInc = "increase"
  } else {
    lastYearInc = "decrease"
  }

// Calculate percentage change between last 12 months and previous 12 months.
  const percChan = (Math.round(((lastYearPrice - prevPrice) / prevPrice) * 10000)/100)

  return (
    <Styled.root>
      <main>
        <div className="container">
        <Header />
        <Helmet>
              <title>{locaC} Housing Prices - YourLocalArea</title>
              <html lang={`en`} />
            <meta
                 name="description"
                 content={`The average property price in ${locaC} is ${lastYearPrice}.`}
            />
              </Helmet>
            <div class="centreMe"> 
            {breadcrumbs} 
            <p class="lastUpdate">Last updated: {data.allSite.nodes[0].siteMetadata.lastUpdated}</p>
              <h1>{locaC} Housing Prices</h1>
              <table>
                <tr>
                  <th>Avg. Price</th>
                  <th>Sales</th>
                  <th>Perc. Change</th>
                </tr>
                <tr>
                  <td>{lastYearPriceF}</td>
                  <td>{sales}</td>
                  <td>{percChan}%</td>
                </tr>
              </table>
              <p>There were {sales} properties sold in {locaC} over the last 12 months. The average price across all property types was {lastYearPriceF} this is a {percChan}% {lastYearInc} compared to the previous year.</p>
            </div>
          <ChartTwo 
            data={data.fifth.nodes}
          />
          <div class="centreMe">
          <p class="copyright">Contains HM Land Registry data © Crown copyright and database right 2020. This data is 
                licensed under the Open Government Licence v3.0.</p>

          {otherAreas}

            </div>
            <ChartFour
          data={data.sixth.nodes}
          />
          <Footer />
        </div>
      </main>
    </Styled.root>
  )
}

export const query = graphql`
  query Weaqtge($nam: String,$loc: String) {
    fifth: allRegionsCsv(filter: {Location: {eq: $loc}}) {
      nodes {
        Location
        Year
        Price
        Sales
        Detached
        Flat
        SemiDetached
        Terraced
        EandW
      }
    }
    sixth: allRegionsCsv(filter: {Location: {eq: $loc}, Year: {lt: "2020"}}) {
      nodes {
        Location
        Year
        IAPrice
      }
    }
    second: allLookCountiesCsv(filter: {B2N: {eq: $nam}}) {
        nodes {
          Name
          Slug
          B2N
        }
      }
    allRegionsRecentCsv(filter: {Location: {eq: $loc}}) {
        nodes {
          Location
          LastYear
          PreviousYear
          LSSales
        }
      }
    third: allLookRegionsCsv(filter: {Location: {eq: $loc}}) {
        nodes {
            Location
            Name
            Slug
            B1N
            B1S
        }
    }
    allLookDistrictsCsv {
      nodes {
        Name
        Slug
      }
    }
    allSite {
      nodes {
        siteMetadata {
          lastUpdated
        }
      }
    }
}`